<template>
  <section class="invoice-preview-wrapper h-100">
    <b-row class="invoice-preview pay-with-token-row py-1 px-1 px-md-2 px-lg-3 px-xl-5">
      <b-col cols="12" v-show="!ready">
        <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="md"></b-overlay>
      </b-col>
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" lg="10" xl="8" v-if="ready">
        <!-- Brand logo-->
        <div class="logo-and-language">
          <b-link class="bilge-logo" :to="{ path: '/' }">
            <b-img :src="brandLogo" alt="Logo" />
            <h2 class="brand-text mb-0 d-none d-md-block" :class="{ 'text-white': skin === 'dark', 'text-primary': skin !== 'dark' }">
              {{ brandTitle }}
            </h2>
          </b-link>
          <locale class="locale-dropdown" />
        </div>
        <!-- /Brand logo-->
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class=" d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 ">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo">
                    {{ transactionDetail.master_company_json.company_trade_name }}
                  </h3>
                </div>
                <p class="card-text mb-0">
                  {{ transactionDetail.master_company_json.tax_number }} -
                  {{ transactionDetail.master_company_json.tax_office_name }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionDetail.master_company_json.address_line1 }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionDetail.master_company_json.post_code }} - {{ transactionDetail.master_company_json.town_name }} /
                  {{ transactionDetail.master_company_json.city_name }}
                  {{ transactionDetail.master_company_json.country_name }}
                </p>
                <p class="card-text mb-0">
                  {{ phoneFormat(transactionDetail.master_company_json.phone) }}
                </p>
                <p class="card-text mb-0">
                  {{ transactionDetail.master_company_json.e_mail }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ $t("transaction.no") }}
                  <span class="invoice-number">{{ `#${transactionId}` }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t("transaction.date") }}
                  </p>
                  <p class="invoice-date">{{ transactionDate }}</p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" md="6" class="p-0">
                <h6 class="mb-2">
                  {{ $t("transaction.payment_info") }}
                </h6>
                <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo">
                    {{ transactionDetail.user_name_surname }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{ transactionDetail.company_json.company_trade_name }}
                </p>
                <p class="card-text mb-0">
                  {{ transactionDetail.company_json.tax_number }} -
                  {{ transactionDetail.company_json.tax_office_name }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionDetail.company_json.address_line1 }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionDetail.company_json.post_code }} - {{ transactionDetail.company_json.town_name }} /
                  {{ transactionDetail.company_json.city_name }}
                  {{ transactionDetail.company_json.country_name }}
                </p>
                <p class="card-text mb-0">
                  {{ phoneFormat(transactionDetail.company_json.phone) }}
                </p>
                <p class="card-text mb-0">
                  {{ transactionDetail.company_json.e_mail }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col cols="12" md="6" class="p-0 mt-xl-0 mt-2 d-flex justify-content-end pr-2">
                <div>
                  <h6 class="mb-2">
                    {{ $t("transaction.payment_detail") }}
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          {{ $t("transaction.total_amount") }}
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ showPrice(transactionDetail.total_amount) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t("transaction.bank_name") }}
                        </td>
                        <td>{{ transactionDetail.bank_id ? transactionDetail.bank_id : $t("payment.table.undefined")}}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t("transaction.ip_address") }}
                        </td>
                        <td>{{ transactionDetail.ip_address }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t("transaction.type") }}
                        </td>
                        <td>{{ transactionDetail.transaction_type_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          {{ $t("transaction.state") }}
                        </td>
                        <td>
                          {{ transactionDetail.transaction_state_name }}
                        </td>
                      </tr>
                      <tr v-if="transactionDetail.erp_master_id">
                        <td class="pr-1">ERP ID:</td>
                        <td>
                          {{ transactionDetail.erp_master_id }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="transactionDetail.payment_detail" :fields="paymentDetailFields">
            <template #cell(com)="data">
              {{ showCommissionRate(data.value) }}
            </template>
            <template #cell(total)="data">
              {{ showPrice(data.value) }}
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">
                    {{ $t("transaction.bank_response") }}
                  </span>
                  <span class="ml-75">
                    {{ transactionDetail.detail_desc }}
                  </span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("transaction.amount") }}
                    </p>
                    <p class="invoice-total-amount">
                      {{ showPrice(transactionDetail.amount) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("transaction.commission") }}
                    </p>
                    <p class="invoice-total-amount">
                      {{ showPrice((transactionDetail.amount * transactionDetail.commission) / 100) }}
                    </p>
                  </div>
                  <hr class="my-50" style="width: 250px" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("transaction.total_amount") }}
                    </p>
                    <p class="invoice-total-amount">
                      {{ showPrice(transactionDetail.total_amount) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">
              {{ $t("transaction.note") }}
            </span>
            <span>{{ transactionDetail.master_desc }}</span>
          </b-card-body>
        </b-card>
        <div class="d-flex justify-content-between print-button-div">
          <app-footer />
          <div>
            <b-button :to="{name:'PayWithToken',params:{hash}}" variant="success" v-if="transactionDetail.transaction_state === 2">
              {{$t('address.go_to_pay')}}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="ml-75" @click="printInvoice">
              {{ $t("transaction.print") }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BModal, BFormGroup, BFormTextarea, BSpinner, BImg } from "bootstrap-vue";
import Locale from "@/layouts/components/Locale.vue";
import AppFooter from "@/@core/layouts/components/AppFooter.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { GET_TRANSACTION_DETAIL_FOR_LINK_PAY } from "@/store/services/payment-service";
import { mapGetters } from "vuex";
import { priceFormat } from "@/assets/js/functions/currency-functions";

export default {
  name: "pay-with-token-detail",
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BOverlay,
    BModal,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BImg,

    Locale,
    AppFooter,
  },
  data() {
    return {
      ready: false,
      transactionDetail: null,
      transactionId: this.$route.params.id,
      hash: this.$route.params.hash,
      paymentNote: "",
      buttonDisable: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(["getLocale", "brandLogo", "brandTitle", "appConfig/getThemeSkin"]),
    transactionDate() {
      return moment(this.transactionDetail?.transaction_date).format("DD.MM.YYYY HH:mm:ss");
    },
    paymentDetailFields() {
      return [
        {
          key: "cc_holder",
          label: this.$t("transaction.credit_card_holder"),
          tdClass: "text-nowrap text-uppercase",
        },
        {
          key: "cc_no",
          label: this.$t("transaction.credit_card"),
          tdClass: "text-nowrap",
        },
        {
          key: "ins",
          label: this.$t("transaction.installment"),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "e_ins",
          label: this.$t("transaction.extra_installment"),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "com",
          label: this.$t("transaction.commission"),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "total",
          label: this.$t("transaction.total"),
          tdClass: "text-right text-nowrap",
          thClass: "text-right",
        },
      ];
    },
    skin() {
      return this["appConfig/getThemeSkin"];
    },
  },
  methods: {
    getData() {
      this.ready = false;
      this.$store.dispatch(GET_TRANSACTION_DETAIL_FOR_LINK_PAY, { transaction_id: this.transactionId, hash: this.hash }).then((response) => {
        this.ready = true;
        this.transactionDetail = response;
      });
    },
    printInvoice() {
      window.print();
    },
    showPrice(price) {
      return priceFormat(price, this.getLocale, "TRY");
    },
    showCommissionRate(rate) {
      return (
        new Intl.NumberFormat(this.getLocale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(rate) + " %"
      );
    },
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.invoice-total-wrapper {
  margin-right: 100px;
}
.invoice-total-item {
  width: 250px;
}
.invoice-logo {
  max-width: 500px;
  font-size: 1.25rem !important;
  margin: 0 !important;
}
.invoice-date-title {
  width: auto !important;
}

.pay-with-token-row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.logo-and-language {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 2rem;
  .bilge-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
    }
    h2 {
      font-weight: 600;
    }
  }
  .locale-dropdown {
    display: flex;
  }
}
</style>

<style lang="scss">
div:has(.pay-with-token-row) {
  height: 100%;
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none !important;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    height: auto!important;
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .print-button-div {
    display: none !important;
  }
  .breadcrumbs-top {
    display: none;
  }

  .logo-and-language {
    display: none !important;
  }

  .btn-scroll-to-top{
    display: none!important;
  }
}
</style>
